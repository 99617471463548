import React from "react";
import { Box } from "@mui/material";
import HeroImage from "../../components/Hero Image/HeroImage";
import pitmasterBg from "../../Assets/pitmaster-bg.jpg";
import PitMasterContent from "./PitMaster Content/PitMasterContent";

const PitMaster = () => {
  return (
    <Box>
      <HeroImage image={pitmasterBg} />
      <PitMasterContent />
    </Box>
  );
};

export default PitMaster;
