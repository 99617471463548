import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import { orange, brown, red } from "@mui/material/colors";
import Image from "mui-image";

import stamp1 from "../../../Assets/stamp1.png";

import stamp2 from "../../../Assets/stamp2.png";

const HomeDetails = () => {
  const getSeason = () => {
    const month = new Date().getMonth(); // Get current month (0-11)
    if (month >= 3 && month <= 5) {
      return "SPRING";
    } else if (month >= 6 && month <= 8) {
      return "SUMMER";
    } else if (month >= 9 && month <= 11) {
      return "AUTUMN";
    } else {
      return "WINTER";
    }
  };
  const currentSeason = getSeason();
  return (
    <Box>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "50px",
          textAlign: "center",
          alignItems: "center",
          padding: "30px 0",
          backgroundColor: red[900],
        }}
      >
        <Typography variant="h3">
          Get Your BBQ Order Here: <br />
          682-262-7553
        </Typography>
        <Typography variant="h3" sx={{ padding: "0 10%", lineHeight: "" }}>
          {currentSeason} SEASON
          <br /> Is Here..! <br />
          Let's BBQ!!
        </Typography>

        {/* <Typography sx={{ padding: "0 30%" }} variant="h5">
          Fine Authentic Texas BBQ Holiday Meals are available with 48hrs
          advance notice.
        </Typography> */}
        <Button
          size="large"
          color="inherit"
          sx={{ width: "30%" }}
          variant="outlined"
          href="/menu"
        >
          <Typography variant="h6">View Menu</Typography>
        </Button>
      </Container>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "40px",
          textAlign: "center",
          alignItems: "center",
          padding: 3,
          backgroundColor: brown[900],
        }}
      >
        <Typography variant="h3">
          <Image
            duration="0"
            src={stamp1}
            style={{ height: "250px", width: "250px" }}
          />
          AUTHENTIC PREMIUM TEXAS BBQ <br /> TRUE TEXAS FLAVOR
        </Typography>

        <Typography fontFamily="roboto" sx={{ padding: "0 20%" }} variant="p">
          Our Premium BBQ is delicious tasting and we crafted it with love
          <br /> We use premium meats and fine herbs and spices. Our meats are
          slowed cooked to perfection in-house over natural Texas hickory wood.
          <br />
          All of our catering comes with choices of sides made by our team of
          chefs. We offer coleslaw, homestyle potato salad, mashed potatoes,
          green beans, mac n cheese, scalloped potatoes and jalapenos pinto
          beans. Our desserts are yummy as well with various pies selections and
          more. Catering can be picked up or delivered to your location. Holler
          Valerie and get a quote.
          <br />
        </Typography>
        <Typography fontFamily="roboto" sx={{ px: "20%" }} variant="p">
          Best BBQ Brisket! Best Pulled Pork! Best Smoked Turkey! Best Sausage!
          Best Smoked Whole Chicken! Best Smoked BBQ Pork Ribs! Best Smoked BBQ
          Beef Ribs!
        </Typography>

        <Typography variant="h6">Oh So So Good! God Help Us All!</Typography>
      </Container>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "50px",
          textAlign: "center",
          alignItems: "center",
          padding: "30px 0",
          backgroundColor: orange[900],
        }}
      >
        <Typography variant="h3">
          <Image
            duration="0"
            src={stamp2}
            style={{ height: "280px", width: "300pxx" }}
          />
          Oh So Mouth Watering Delicious BBQ <br />
          Slow Smoke Hickory TX Wood
        </Typography>
        <Typography fontFamily="roboto" sx={{ padding: "0 20%" }} variant="p">
          We specialize in Authentic Texas BBQ. For over 40yrs, we have been
          dedicated to perfecting the perfect Authentic Texas BBQ with premium
          meats. We are passionate about Smokin' BBQ. Robert and Valerie Austin
          family team love to cater your events or parties. Robert Austin,
          Co-Founder and Award-winning PitMaster along with his wife and
          co-Founder Valerie Austin are a dynamic duo.
          <br />
        </Typography>
        <Typography variant="h6">
          Give us a Holler! We luv' Foat Wuth!! Foat Wuth luvs our BBQ!
        </Typography>
        <Button
          size="large"
          color="inherit"
          sx={{
            width: "25%",
          }}
          variant="outlined"
          href="/menu"
        >
          <Typography variant="h6">View Menu</Typography>
        </Button>
      </Container>
    </Box>
  );
};

export default HomeDetails;
