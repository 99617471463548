import React from "react";
import theme from "./theme/Theme";
import Home from "./pages/Home/Home";
import { ThemeProvider, CssBaseline } from "@mui/material";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import CaterinBbqQuote from "./pages/Caterin Bbq Quote/CaterinBbqQuote";
import SmokinBbqMenu from "./pages/Smokin Bbq Menu/SmokinBbqMenu";
import PitMaster from "./pages/PitMaster/PitMaster";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<SmokinBbqMenu />} />
        <Route path="/bbqquote" element={<CaterinBbqQuote />} />
        <Route path="/aboutus" element={<PitMaster />} />
      </Routes>

      <Footer />
    </ThemeProvider>
  );
};

export default App;
