import React from "react";
import { Box } from "@mui/material";
import menuBg from "../../Assets/menu-bg.png";
import HeroImage from "../../components/Hero Image/HeroImage";
import MenuContent from "./MenuContent/MenuContent";
const SmokinBbqMenu = () => {
  return (
    <Box>
      <HeroImage image={menuBg} />
      <MenuContent />;
    </Box>
  );
};

export default SmokinBbqMenu;
