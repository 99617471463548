import { Box } from "@mui/material";
import React from "react";
import HeroImage from "../../components/Hero Image/HeroImage";
import bbqImage from "../../Assets/bbq-quote-bg.png";
import BbqQuoteContent from "./Bbq Quote Content/BbqQuoteContent";

const BbqQuote = () => {
  return (
    <Box>
      <HeroImage image={bbqImage} />
      <BbqQuoteContent />
    </Box>
  );
};

export default BbqQuote;
