import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { brown } from "@mui/material/colors";

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      primary: {
        main: brown[900],
      },
      secondary: {
        main: "#f8f9fa",
      },
      background: {
        default: brown[800],
        paper: brown[800],
      },
      text: {
        primary: "rgba(255,254,254,0.87)",
        secondary: "rgba(255,254,254,0.87)",
      },
    },
    typography: {
      h1: {
        fontFamily: ' "Wallpoet", cursive;',
      },
      h2: {
        fontFamily: ' "Wallpoet", cursive;',
      },
      h3: {
        fontFamily: ' "Wallpoet", cursive;',
      },
      h4: {
        fontFamily: ' "Wallpoet", cursive;',
      },
      h5: {
        fontFamily: ' "Wallpoet", cursive;',
      },
      h6: {
        fontFamily: ' "Wallpoet", cursive;',
      },
    },
  })
);

export default theme;
